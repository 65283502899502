<template>
  <div class="wrapper">
    <el-button
      v-if="!isOn"
      type="primary"
      style="marginBottom:20px"
      @click="operateVideo"
    >
      {{ $t("guide.start") }}
    </el-button>
    <el-button
      v-else
      type="primary"
      style="marginBottom:20px"
      @click="operateVideo"
    >
      {{ $t("guide.close") }}
    </el-button>
    <div class="videoWrapper">
      <video
        id="guideVideo"
        ref="guideVideo"
        preload
        controls
        :src="`${url}videos/fast_start.mp4`"
      />
    </div>
  </div>
</template>

<script>
import { customConfig } from '@/api/request.js'
export default {
  data () {
    return {
      url: customConfig.baseURL,
      isOn: false
    }
  },
  methods: {
    operateVideo () {
      const video = this.$refs.guideVideo
      // 进入画中画事件
      video.addEventListener('enterpictureinpicture', (e) => {
        this.isOn = true
        console.log(e, 'enter')
      })
      // 离开画中画事件
      video.addEventListener('leavepictureinpicture', (e) => {
        this.isOn = false
        console.log(e, 'leave')
      })

      if (!document.pictureInPictureElement) {
        video.requestPictureInPicture().catch((e) => {
          console.log(e)
        })
      } else {
        document.exitPictureInPicture().catch((e) => {
          console.log(e)
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.wrapper {
  box-sizing: border-box;
  padding: 20px 80px 20px 60px;
  height: 100%;
  /* display: flex; */
  .videoWrapper {
    height: 90%;
    #guideVideo {
      /* flex-grow: 1; */
      height: 100%;
      width: 100%;
    }
  }
}
</style>
